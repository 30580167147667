
  button{
    display: block;

    border-radius: 10px;
    border: none;
    background-color: #3a54ff;
  }

  button[slot="hotspot-hand"]{
    --min-hotspot-opacity: 0;
    background-color: #3a54ff;

  }

 /*  button[slot="hotspot-foot"]:not([data-visible]) {
    background-color: transparent;
    border: 3px solid #3a54ff;
  } */

  #annotation{
    background-color: #888888;
    position: absolute;
    transform: translate(10px, 10px);
    border-radius: 10px;
    padding: 10px;
    color: #000;
  }
  /* This keeps child nodes hidden while the element loads */
  :not(:defined) > * {
    display: none;
  }

body {
background-color: #121212;/* 
background: #121212;
color: #121212; */
/* width: 100vw;
max-width: 100vw; */
  }

model-viewer {
/*    width: 800px;
    height: 800px;  */

  /*  --poster-color: #ffffff00;*/
width: 100%;
height: 920px;
/* height: 1120px; */
  outline: black;
  border: black ;
  background-color: white;
  background:  #191919;
/* color: yellow; */
outline-color: #191919;
fill-opacity: 0;
-webkit-tap-highlight-color: none;
backface-visibility: hidden;
padding-bottom: 30px;
outline-style: hidden;
padding-bottom: 20vh;
}

@media (max-width:680px)
{model-viewer  {
  width: 100%;
  height: 74vh;padding-bottom: 20vh;
}
}

.p {
font-size: 25px;
}




.slider {
  width: 100%;
  text-align: center;
  overflow: hidden;
  position: absolute;
  bottom: 16px;
}

.slides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.slide {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  margin-right: 10px;
  border-radius: 10px;
  border: none;
  display: flex;
}

.slide.selected {
  border: 2px solid #4285f4;
}

.slide:focus {
  outline: none;
}

.slide:focus-visible {
  outline: 1px solid #4285f4;
}








/* 
  width: 100%;
  max-width: 700px;
  height: 125vw;
    outline: black;
    border: black ;
    background-color: white;
    background:  #121212;
   outline-color: #121212;
  fill-opacity: 0;
  -webkit-tap-highlight-color: none;
  backface-visibility: hidden;
  padding-bottom: 30px;
  outline-style: hidden;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 125vw;
    max-width: 700px;
  } */
  







  

  #ar-button {
    background-image:  url(aricon.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 12px 50%;
    background-color: #fff;
    position: relative;
   /*  z-index: 1; */
    transform: translateX(90%);
   margin-top: 60vh;
    white-space: nowrap;
    bottom: 8px;
    padding: 0px 16px 0px 40px;
  /*   padding-bottom: -50px; */
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 14px;
    /* color:#4285f4; */
    color:#4285f4;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #DADCE0;
   
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}


@media (min-width:690px)
{#ar-button   {
  margin-top: 70vh;
  padding-left: 30px;
}
}


@-webkit-keyframes glowing {
  0% { background-color: white; -webkit-box-shadow: 0 0 3px #3a54ff; }
  50% { background-color: white; -webkit-box-shadow: 0 0 40px #3a54ff; }
  100% { background-color: white; -webkit-box-shadow: 0 0 3px #3a54ff; }
}

@-moz-keyframes glowing {
  0% { background-color: white; -moz-box-shadow: 0 0 3px #3a54ff; }
  50% { background-color: white; -moz-box-shadow: 0 0 40px #3a54ff; }
  100% { background-color: white0; -moz-box-shadow: 0 0 3px #3a54ff; }
}

@-o-keyframes glowing {
  0% { background-color: white; box-shadow: 0 0 3px#3a54ff; }
  50% { background-color: white; box-shadow: 0 0 40px#3a54ff; }
  100% { background-color: white; box-shadow: 0 0 3px#3a54ff; }
}

@keyframes glowing {
  0% { background-color: white; box-shadow: 0 0 3px #3a54ff; }
  50% { background-color: white; box-shadow: 0 0 40px #3a54ff; }
  100% { background-color: white; box-shadow: 0 0 3px #3a54ff; }
}

 /*  #ar-button:active {
    background-color: #E8EAED;
  }
 */
  #ar-button:focus {
    outline: none;
  }
  #ar-button:active:after  {
 
    background-color:black;
  }
  
  #ar-button:active:after {
    background: transparent;
}

#ar-button:active {
/*   transform: translateY(-1px);
  background-color:black; */
  -moz-animation: glowing 1500ms ;
  -o-animation: glowing 1500ms ;
  animation: glowing 1500ms ;

}
#ar-button:after {
  /*   transform: translateY(-1px);
    background-color:black; */
    -moz-animation: glowing 1500ms ;
    -o-animation: glowing 1500ms ;
    animation: glowing 1500ms ;
  
  }

  #ar-button:focus-visible {
    -moz-animation: glowing 1500ms ;
    -o-animation: glowing 1500ms ;
    animation: glowing 1500ms ;
  }
  #ar-button:focus {
    -moz-animation: glowing 1500ms ;
    -o-animation: glowing 1500ms ;
    animation: glowing 1500ms ;
  }
  #ar-button:focus-visible {
    outline: 1px solid #4285f4;
  }

  
  @keyframes circle {
    from { transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
    to   { transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
  }

  @keyframes elongate {
    from { transform: translateX(100px); }
    to   { transform: translateX(-100px); }
  }

  model-viewer > #ar-prompt {
    position: absolute;
    left: 50%;
    bottom: 175px;
    animation: elongate 2s infinite ease-in-out alternate;
    display: none;
  }

  model-viewer[ar-status="session-started"] > #ar-prompt {
    display: block;
  }

  model-viewer > #ar-prompt > img {
    animation: circle 4s linear infinite;
  }

  model-viewer > #ar-failure {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 175px;
    display: none;
  }

  model-viewer[ar-tracking="not-tracking"] > #ar-failure {
    display: block;
  }

  .slider {
/*     width: 100%; */
    text-align: center;
    overflow: hidden;
    position: absolute;
    bottom: -820px;
    padding-left:80px ;
  }

  @media (max-width:680px)
  {.slider   {
    bottom: -850px;
    padding-left:20px ;
    margin-bottom: 50px;
  }
  }



  .slides {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .slide {
   /*  scroll-snap-align: start; */
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: grey;
    margin-right: 10px;
    border-radius: 10px;
    border: none;
    display: flex;
  }

  .slide.oel {
    border: 2px solid #4285f4;
    background-image:  url(oellampe.png);

  }

  .slide.art {
    border: 2px solid #4285f4;
    background-image:  url(artdecovogel.png);

  }

  .slide.geo {
    border: 2px solid #4285f4;
    background-image:  url(snap.png);

  }

  .slide:focus {
    outline: none;
  }

  .slide:focus-visible {
    outline: 1px solid #4285f4;
  }



/*   
  .ModelButton{
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; 
     bottom: -100px;
  }

  .box{
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: grey;
    margin-right: 10px;
    border-radius: 10px;
    border: none;
    display: flex;
    transition: background-color 0.1s ease-in-out;
  }



  .inactive1{
    background-color: #3867d6;
  }

  .active1{
    background-color: #26de81;
    border: 2px solid #4285f4;
  
  }

  #box1{
    background-color: #fa8231;
  } */