

.buttonlaingaus {
  font-size: 1.42rem;

  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 22px;
  border: black 0.125em solid;
  padding: 1.48em 1.2em;
 /*  border-radius: 0.52em; */

 
  color:black;
  box-shadow:black;
  text-shadow:  inset 0.00751em 0 2.18em 0 var(black), 0.051em 0.051em 0.32em 0 var(black);
  transition: black, text-shadow, box-shadow,  800ms cubic-bezier(0.061, 0, 0, 0.061);
  
   justify-content: center ;text-align: center;







   
}

@media (max-width:1280px)
{.buttonlaingaus  {
text-align: center;
  padding: 1.22em 0.01em;
width: 40vw;
border: var(black) 0.17em solid;
font-size: 1.42rem;
box-shadow: black, 0.051em 0.051em 0.8em 0 var(black);
}
}

/* .neon-button::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.25);
  filter: blur(1.2em);
  opacity: 1;
}
 */
 
 .buttonlaingaus::after {
 /*  content: ""; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
/*   box-shadow: 0 20em 1.2em 5.5em var(--clr-neon);
 */
 transform: perspective(3em) rotateX(40deg) scale(2, 3.25);

  opacity: 0;
  z-index: -1;
  transition: opacity 100ms linear;
}

.buttonlaingaus:hover,
.buttonlaingaus:focus {
  color: var(--clr-bg);
 box-shadow: inset 0.00751em 0 1.6em 0 var(black), 0.01em 0.01em 5.32em 0 var(black);
/*  text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 1.32em 0 var(white);
 */ color:blue;
 
 border: 0.124em solid;
 border: solid;

 /*  text-shadow: 0 0 0.025em hsl(0 0% 100% / 1.3), 0 3em 0.45em currentColor; */
 transition: color, text-shadow, box-shadow, border, 200ms linear;
}

.buttonlaingaus:hover::before,
.buttonlaingaus:focus::before {
  opacity: 1;


}
.buttonlaingaus:hover::after,
.buttonlaingaus:focus::after {
  opacity: 1;

}


















































.buttonlaing {
  font-size: 1.42rem;

  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 22px;
  border: black 0.125em solid;
  padding: 1.48em 1.2em;
 /*  border-radius: 0.52em; */

 
  color:black;
  box-shadow:black;
  text-shadow:  inset 0.00751em 0 2.18em 0 var(black), 0.051em 0.051em 0.32em 0 var(black);
  transition: black, text-shadow, box-shadow,  800ms cubic-bezier(0.061, 0, 0, 0.061);
  
   justify-content: center ;text-align: center;







   
}

@media (max-width:1280px)
{.buttonlaing  {
text-align: center;
  padding: 1.22em 0.01em;
width: 40vw;
border: var(black) 0.17em solid;
font-size: 1.42rem;
box-shadow: black, 0.051em 0.051em 0.8em 0 var(black);
}
}

/* .neon-button::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.25);
  filter: blur(1.2em);
  opacity: 1;
}
 */
 
 .buttonlaing::after {
 /*  content: ""; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
/*   box-shadow: 0 20em 1.2em 5.5em var(--clr-neon);
 */
 transform: perspective(3em) rotateX(40deg) scale(2, 3.25);

  opacity: 0;
  z-index: -1;
  transition: opacity 100ms linear;
}

.buttonlaing:hover,
.buttonlaing:focus {
  color: var(--clr-bg);
 box-shadow: inset 0.00751em 0 1.6em 0 var(black), 0.01em 0.01em 5.32em 0 var(black);
/*  text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 1.32em 0 var(white);
 */ color:blue;
 
 border: 0.124em solid;
 border: solid;

 /*  text-shadow: 0 0 0.025em hsl(0 0% 100% / 1.3), 0 3em 0.45em currentColor; */
 transition: color, text-shadow, box-shadow, border, 200ms linear;
}

.buttonlaing:hover::before,
.buttonlaing:focus::before {
  opacity: 1;


}
.buttonlaing:hover::after,
.buttonlaing:focus::after {
  opacity: 1;

}





.buttonlaingk {
  font-size: 1.42rem;
margin-bottom: 0px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  margin-top: 80px;
  border: grey 0.125em solid;
  padding: 0.28em 1em;
 /*  border-radius: 0.52em; */

 
  color:grey;
  box-shadow:grey;
  text-shadow:  inset 0.00751em 0 2.18em 0 var(grey), 0.051em 0.051em 0.32em 0 var(grey);
  transition: grey, text-shadow, box-shadow,  800ms cubic-bezier(0.061, 0, 0, 0.061);
  
   justify-content: center ;
}

@media (max-width:680px)
{.buttonlaingk  {
text-align: center;
  padding: 0.22em 0.01em;
width: 40vw;
border: var(grey) 0.17em solid;
font-size: 1.42rem;
box-shadow: grey, 0.051em 0.051em 0.8em 0 var(grey);
}
}

/* .neon-button::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.25);
  filter: blur(1.2em);
  opacity: 1;
}
 */
 
 .buttonlaingk::after {
 /*  content: ""; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
/*   box-shadow: 0 20em 1.2em 5.5em var(--clr-neon);
 */
 transform: perspective(3em) rotateX(40deg) scale(2, 3.25);

  opacity: 0;
  z-index: -1;
  transition: opacity 100ms linear;
}

.buttonlaingk:hover,
.buttonlaingk:focus {
  color: var(--clr-bg);
 box-shadow: inset 0.00751em 0 1.6em 0 var(grey), 0.01em 0.01em 5.32em 0 var(grey);
/*  text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 1.32em 0 var(white);
 */ color:black;
 
 border: 0.124em solid;
 border: solid;

 /*  text-shadow: 0 0 0.025em hsl(0 0% 100% / 1.3), 0 3em 0.45em currentColor; */
 transition: color, text-shadow, box-shadow, border, 200ms linear;
}

.buttonlaingk:hover::before,
.buttonlaingk:focus::before {
  opacity: 1;


}
.buttonlaingk:hover::after,
.buttonlaingk:focus::after {
  opacity: 1;

}



/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 */


.buttonlaingko {
  font-size: 1.42rem;
margin-bottom: 150px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  margin-top: 0px;
  border: grey 0.125em solid;
  padding: 0.28em 1em;
 /*  border-radius: 0.52em; */

 
  color:grey;
  box-shadow:grey;
  text-shadow:  inset 0.00751em 0 2.18em 0 var(grey), 0.051em 0.051em 0.32em 0 var(grey);
  transition: grey, text-shadow, box-shadow,  800ms cubic-bezier(0.061, 0, 0, 0.061);
  
   justify-content: center ;
}

@media (max-width:680px)
{.buttonlaingko  {
text-align: center;
  padding: 0.22em 0.01em;
width: 40vw;
border: var(grey) 0.17em solid;
font-size: 1.42rem;
box-shadow: grey, 0.051em 0.051em 0.8em 0 var(grey);
}
}

/* .neon-button::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.25);
  filter: blur(1.2em);
  opacity: 1;
}
 */
 
 .buttonlaingko::after {
 /*  content: ""; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
/*   box-shadow: 0 20em 1.2em 5.5em var(--clr-neon);
 */
 transform: perspective(3em) rotateX(40deg) scale(2, 3.25);

  opacity: 0;
  z-index: -1;
  transition: opacity 100ms linear;
}

.buttonlaingko:hover,
.buttonlaingko:focus {
  color: var(--clr-bg);
 box-shadow: inset 0.00751em 0 1.6em 0 var(grey), 0.01em 0.01em 5.32em 0 var(grey);
/*  text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 1.32em 0 var(white);
 */ color:white;
 
 border: 0.124em solid;
 border: solid;

 /*  text-shadow: 0 0 0.025em hsl(0 0% 100% / 1.3), 0 3em 0.45em currentColor; */
 transition: color, text-shadow, box-shadow, border, 200ms linear;
}

.buttonlaingko:hover::before,
.buttonlaingko:focus::before {
  opacity: 1;


}
.buttonlaingko:hover::after,
.buttonlaingko:focus::after {
  opacity: 1;

}




/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 */












.neon-button {
  font-size: 1.42rem;

  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  
  border: var(--clr-neon) 0.125em solid;
  padding: 0.28em 1em;
  border-radius: 0.52em;

 
  color: var(--clr-neon);
  box-shadow: inset 0.00751em 0 0.18em 0 var(--clr-neon), 0.051em 0.051em 0.32em 0 var(--clr-neon);
  text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 0.32em 0 var(white);
  transition: color, text-shadow, box-shadow,  800ms cubic-bezier(0.061, 0, 0, 0.061);
  
   justify-content: center ;
}

@media (max-width:680px)
{.neon-button {
text-align: center;
  padding: 0.22em 0.01em;
width: 40vw;
border: var(--clr-neon) 0.17em solid;
font-size: 1.42rem;
box-shadow: inset 0.00751em 0 0.18em 0 var(--clr-neon), 0.051em 0.051em 0.8em 0 var(--clr-neon);
}
}

/* .neon-button::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.25);
  filter: blur(1.2em);
  opacity: 1;
}
 */
 
.neon-button::after {
 /*  content: ""; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
/*   box-shadow: 0 20em 1.2em 5.5em var(--clr-neon);
 */
 transform: perspective(3em) rotateX(40deg) scale(2, 3.25);

  opacity: 0;
  z-index: -1;
  transition: opacity 100ms linear;
}

.neon-button:hover,
.neon-button:focus {
  color: var(--clr-bg);
 box-shadow: inset 0.00751em 0 1.6em 0 var(--clr-neon), 0.01em 0.01em 5.32em 0 var(--clr-neon);
 text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 1.32em 0 var(white);
 color: #b3baff;
 
 border: 0.124em solid;

 /*  text-shadow: 0 0 0.025em hsl(0 0% 100% / 1.3), 0 3em 0.45em currentColor; */
 transition: color, text-shadow, box-shadow, border, 1500ms linear;
}

.neon-button:hover::before,
.neon-button:focus::before {
  opacity: 1;


}
.neon-button:hover::after,
.neon-button:focus::after {
  opacity: 1;

}


:root {
  --clr-neon: #3a54ff;
 --clr-bg:    #3a54ff;
 /* --clr-bg:    #3a54ff; */
/*  --clr-neon: #2634ff;
 */} 

.cursor {
  width: 40px;
  height: 40px;
  border: 2px solid #fefefe;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 150ms ease;
  transition-property: background-color, opacity, transform, mix-blend-mode;
  z-index: 9999;
  mix-blend-mode: difference;
}

.cursor--hidden {
  opacity: 0;
}

.cursor--link-hovered {
  transform: translate(-50%, -50%) scale(1.25);
  background-color: #fefefe;
}

.cursor--clicked {
  transform: translate(-50%, -50%) scale(0.9);
  background-color: #fefefe;
}
/*  html,
body {
    cursor: none;
    
}
html *,
body * {
    cursor: none;
    
} */




.neon-button2 {
  font-size:20px;

  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  
/*   border-bottom: var(--clr-neon) 0.125em solid;
 */  padding: 0.28em 1em;
/*   border-radius: 0.52em;
 */
 
  color: #01bf71;
/*   box-shadow: inset 0.00751em 0 0.18em 0 var(--clr-neon), 0.051em 0.051em 0.32em 0 var(--clr-neon);
 */  text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 0.32em 0 var(white);
  transition: color, text-shadow, box-shadow,  800ms cubic-bezier(0.061, 0, 0, 0.061);
  
   justify-content: center ;
}

@media (max-width:680px)
{.neon-button2 {
padding: 0.28em 1em;

/* border-bottom: var(--clr-neon) 0.17em solid;
 */
font-size: 18px;
/* box-shadow: inset 0.00751em 0 0.18em 0 var(--clr-neon), 0.051em 0.051em 0.8em 0 var(--clr-neon);
 */}
}

/* .neon-button::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.25);
  filter: blur(1.2em);
  opacity: 1;
}
 */
.neon-button2::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
/*   box-shadow: 0 20em 1.2em 5.5em var(--clr-neon);
 */
 transform: perspective(3em) rotateX(40deg) scale(2, 3.25);

  opacity: 0;
  z-index: -1;
  transition: opacity 100ms linear;
}

.neon-button2:hover,
.neon-button2:focus {
  color: var(--clr-bg);
 box-shadow: inset 0.00751em 0 1.6em 0 var(--clr-neon), 0.01em 0.01em 5.32em 0 var(--clr-neon);
 text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 1.32em 0 var(white);
 color:#01bf71;
 
 border: 0.124em solid;

 /*  text-shadow: 0 0 0.025em hsl(0 0% 100% / 1.3), 0 3em 0.45em currentColor; */
 transition: color, text-shadow, box-shadow, border, 1500ms linear;
}

.neon-button2:hover::before,
.neon-button2:focus::before {
  opacity: 1;


}
.neon-button2:hover::after,
.neon-button2:focus::after {
  opacity: 1;

}

/* ///////////////////////////////////////////// */



.neon-button3 {
  font-size: 1.42rem;

  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  
/*   border: var(--clr-neon) 0.125em solid;
 */  padding: 0.25em 1em;
  border-radius: 0.52em;

 
  color: var(--clr-neon);
/*   box-shadow: inset 0.00751em 0 0.18em 0 var(--clr-neon), 0.051em 0.051em 0.32em 0 var(--clr-neon);
 */  text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 0.32em 0 var(white);
  transition: color, text-shadow, box-shadow,  360ms cubic-bezier(0.061, 0, 0, 0.061);
  position: relative; justify-content: center ;
}



.neon-button3::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
/*   box-shadow: 0 20em 1.2em 5.5em var(--clr-neon);
 */
 transform: perspective(3em) rotateX(40deg) scale(2, 3.25);

  opacity: 0;
  z-index: -1;
  transition: opacity 100ms linear;
}

.neon-button3:hover,
.neon-button3:focus {
  color: var(--clr-bg);
 box-shadow: inset 2em 2em 1.6em 0 var(--clr-neon), 0.01em 0.01em 5.32em 0 var(--clr-neon);
 text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 1.32em 0 var(white);
 color: #b3baff;
 
/*  border: 0.124em solid; */

 /*  text-shadow: 0 0 0.025em hsl(0 0% 100% / 1.3), 0 3em 0.45em currentColor; */
 transition: color, text-shadow, box-shadow, border, 600ms linear;
}

.neon-button3:hover::before,
.neon-button3:focus::before {
  opacity: 1;


}
.neon-button3:hover::after,
.neon-button3:focus::after {
  opacity: 1;

}















.neon-icon {
  font-size: 1px;
column-rule-color: blue;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
 
  padding: 0.em;
  border-radius: 10em;
/*   background-color: blue; */
 
  color: #2634ff;
/*   box-shadow: inset 0.00751em 0 0.18em 0 var(--clr-neon), 0.051em 0.051em 0.32em 0 var(--clr-neon);
 */  transition: color, text-shadow, box-shadow,  800ms cubic-bezier(0.061, 0, 0, 0.061);
  position: relative; justify-content: center ;
}

.neon-icon::before {
 pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  color: #2634ff;

  top: 120%;
  left: 0;
  width: 100vw;
  height: 100vw;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.25);
  filter: blur(1.2em);
  opacity: 1; 
}

.neon-icon::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
/*   box-shadow: 0 20em 1.2em 5.5em var(--clr-neon);
 */
/*  transform: perspective(3em) rotateX(40deg) scale(2, 3.25);
 */
  opacity: 0;
  z-index: -1;
  transition: opacity 100ms linear;
}

.neon-icon:hover,
.neon-icon:focus {
  color: var(--clr-bg);
 box-shadow:  /* inset 0.00751em 0 1.6em 0.7em var(--clr-neon) , */ 0.0001em 0.0001em 2.32em 0 var(--clr-neon);
/*  text-shadow:  inset 0.00751em 0 2.18em 0 var(white), 0.051em 0.051em 1.32em 0 var(white);
 */ color: #b3baff;
 
/*  border: 0.124em solid; */

 /*  text-shadow: 0 0 0.025em hsl(0 0% 100% / 1.3), 0 3em 0.45em currentColor; */
/*  transition: color, text-shadow, box-shadow, border, 1500ms linear;
 */}

.neon-icon:hover::before,
.neon-icon:focus::before {
  opacity: 1;


}
.neon-icon:hover::after,
.neon-icon:focus::after {
  opacity: 1;

}