




form {
/*   height: 100vh; */
  width: 600px; 
  max-width: 80vw;
  margin: 0 auto;  
  padding-bottom: 100px;
  /* padding-top: 80px;  */
  justify-content: center;padding-top: 10vw;    color: #fff;
}


 h1 {
  color: white; 
/*   font-weight: 100;
  text-align: center; 
   padding-bottom: 10px;
  border-bottom: 3px solid  #3a54ff; */
 
}

.form {
  background: #121212;
  max-width: 400px;
  margin: 0 auto;
  height: 800px;
  padding-bottom: 100px;
  margin-top: 25vw;color: black; 
  color: #fff;
}

.form > input,
textarea {
  padding: 20px;
  border-radius: 3px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  border: 1px solid lightgray;
  /* border: none; */
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;  
  width: 32vw;
  min-width: 290px;
}

.Checkbox
{
  color: #fff;
  
}


.form > textarea {
  height: 250px;
  color: black; 
  max-width: 80vw;
  min-height: 100px;
}


.label {
  padding-bottom: 10px;
  font-weight: bold;
}



@media (max-width:680px)
{.form   {
  width: 50vw;
  height:100vh;
  padding-top: 25vw;
}

}
/* p {
  color: #bf1650;
}

p::before {
  display: inline;
  content: "⚠ ";
} */

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;color: black; 
  
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
/*   color: white; */
  font-size: 14px;
  font-weight: 200;
}

button[type="submit"],
input[type="submit"] {
  background: #121212;
  color: #3a54ff;
  ;
  text-transform: uppercase;
  width: 50%;
  margin-top: 40px;
  padding: 10px;
  font-size: 24px;
  font-weight: 700;
  /* letter-spacing: 6px; */
  margin-left: 25%;
}

button[type="submit"]:hover,
input[type="submit"]:hover {
  background:   #3a54ff;
  ;
}

button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

button[type="submit"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}



button[type="button"] {
  display: block;
  appearance: none;
 /*  background: #333; */
 /*  color: white; */

  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

/* hr {
  margin-top: 30px;
} */

/* button {
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;margin-bottom: 100px;
} */
