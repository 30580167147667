*{
box-sizing: border-box;
margin: 0;
padding: 0;
font-family: "Encode Sans",
sans-serif;
color: white;
/*  cursor:url("http://scivis3d.com/wp-content/uploads/2021/05/cursor9.png")  10 10 , auto;
 */

}
.font-face-gm {
    font-family: "Geometric706";
   }
