a{
  text-decoration: none;
  
}
.neon-buttonNav {
    font-size: 1.2rem;
  
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    
    border: var(--clr-neon) 0.18em solid;
    padding: 0.42em 1em;
  /*   border-radius: 1.52em; */
  
  text-decoration: none;
    color: var(--clr-neon);
  /*   box-shadow: inset 0 0 0.18em 0 var(--clr-neon), 0 0 0.52em 0 var(--clr-neon);
    text-shadow:  inset 0 0 2.18em 0 var(white), 0 0 2.52em 0 var(white); */
    transition: color, text-shadow, box-shadow,  420ms cubic-bezier(0.021, 0, 0, 0.021);
    position: relative;
    margin-top: 15px;
    
  }
  
  .neon-buttonNav::before {
    pointer-events: none;text-decoration: none;
/*     content: "";
    position: absolute;
    background: var(--clr-neon);
    top: 120%;Sign In
    left: 0;
    width: 100%;
    height: 100%;text-decoration: none;
     transform: perspective(1em) rotateX(40deg) scale(1, 0.25);
  filter: blur(1.2em);
    opacity: 0.7; */
  }
  
  .neon-buttonNav::after {
   /*   content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    box-shadow: 0 0.001em 0.0002em 0.0005em var(--clr-neon);
    text-decoration: none;
    opacity: 0;
    background-color: var(--clr-neon);
    z-index: -1;
    transition: opacity 100ms linear; 
  }
  
  .neon-buttonNav:hover,
  .neon-buttonNav:focus {
    color: var(--clr-bg);
    box-shadow: inset 0.0751em 0 3em 0 var(--clr-neon), 0.01em 0.01em 2.832em 0 var(--clr-neon);
   text-shadow:  inset 0.0751em 0 0.018em 0 var(white), 0.051em 0.051em 0.032em 0 var(white);
   color: #b3baff;
   text-decoration: none;
   border: 0.18em solid;
     text-shadow: 0 0 0.025em hsl(0 0% 100% / 1.3), 0 0em 0.45em currentColor; 
   transition: color, text-shadow, box-shadow,  620ms linear;
  }
  
  .neon-buttonNav:hover::before,
  .neon-buttonNav:focus::before {
    opacity: 1;
  
  
  }
  .neon-buttonNav:hover::after,
  .neon-buttonNav:focus::after {
    opacity: 1;
  
  }
  
  
  :root {
    --clr-neon: #2634ff;
   --clr-bg:   #2634ff;
  } 