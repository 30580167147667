
.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

}

.loading-bar-container {
  width: 250px;
  height: 38px;
  background: #272727;
}

.loading-bar {
  height: 38px;
  background: #190eaa;
}

.loadingText {
  color: #fff;
  font-weight: 600;
  padding: 16px 24px;
  border-radius: 10px;
}

canvas {
  width: 735px;
/* width: 100vw;
height:100vh; */


   height:735px;

  z-index: -8;
}