

.fade-in {
    opacity:1;
    transition: opacity 1s ease;
}

.fade-out {
    opacity: 0;
    transition: opasity 1s ease;

}

h1  {
    color: #FF3B26;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 18px;
    opacity: 0;
}



.p1{

margin-top: 24px;

margin-bottom: 24px;
font-size: 52px;
line-height:1.15;
font-weight: 600;
color: #010606;
opacity: 1;
@media screen and (max-width: 480px) {
    font-size: 32px;
    text-align:center;
}
}
.p2{

max-width: 440px;
margin-bottom: 55px;
font-size: 18px;
line-height: 24px;
color: #010606;
opacity: 1;

}

.b1{

opacity: 1;
}