.DownloadApp{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

